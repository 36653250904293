import { authenticatedRequest } from "../../../utils/axios/commonAxios";
import { UpdateStatusProps } from "./BuddyRatingSlice";

export const getBuddyRatingListAPIRequest = async () => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/buddy-ratings`,
    "get",
    {}
  );
};

export const updateRateStatusAPIRequest = async (data: UpdateStatusProps) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/buddy-ratings/${data?.id}`,
    "post",
    {
      data: { status: data?.status },
    }
  );
};
