import { CreateUniversityProps } from "../../../Models/University";
import { authenticatedRequest } from "../../../utils/axios/commonAxios";

export const createUnivertyAPIRequest = async (
  university: CreateUniversityProps
) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/`,
    "post",
    {
      data: university,
    }
  );
};
