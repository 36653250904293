import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { getUniversityListAPIRequest } from "./universityListAPI";
import { UniversityListProps } from "../../../Models/University";

interface CounterState {
  gettingUniversityList: boolean;
  getUniversityListStatus: "IDLE" | "PENDING" | "SUCCESS" | "FAILED";
  getUniversityListResponse: [];
}

const initialState: CounterState = {
  gettingUniversityList: false,
  getUniversityListStatus: "IDLE",
  getUniversityListResponse: [],
};

export const getUniversityListRequest = createAsyncThunk(
  "university/getUniversities",
  async () => {
    return await getUniversityListAPIRequest();
  }
);

const getUniversityListSlice = createSlice({
  name: "getUNiversityList",
  initialState: initialState,
  reducers: {
    clearUniversityList: (state) => {
      state.gettingUniversityList = false;
      state.getUniversityListStatus = "IDLE";
      state.getUniversityListResponse = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUniversityListRequest.pending, (state) => {
        state.getUniversityListStatus = "PENDING";
        state.gettingUniversityList = true;
      })
      .addCase(getUniversityListRequest.fulfilled, (state, action: any) => {
        // Add user to the state array
        state.getUniversityListStatus = "SUCCESS";
        state.gettingUniversityList = false;
        state.getUniversityListResponse = action.payload;
      })
      .addCase(getUniversityListRequest.rejected, (state) => {
        state.getUniversityListStatus = "FAILED";
        state.gettingUniversityList = false;
      });
  },
});

export const getUniversityListSelector = (state: RootState) => state.getUniversityList;

export const {clearUniversityList} = getUniversityListSlice.actions

export default getUniversityListSlice.reducer
