import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import loginReducer from '../layouts/login/loginSlice';
import createReducer from '../features/universityManagement/createUniversity/createUniversitySlice';
import universityListReducer from '../features/universityManagement/universityList/universityListSlice';
import universityReducer from '../features/universityManagement/universityDetail/detailSlice';
import buddyRateReducer from '../features/rating/buddyRating/BuddyRatingSlice';
import adminReducer from '../features/universityManagement/common/administration/administrationSlice';
import getUserHistoryListReducer from '../layouts/user-history/UserHistoryListSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    login: loginReducer,
    createUniversity: createReducer,
    getUniversityList: universityListReducer,
    getUniversity: universityReducer,
    getBuddyRate: buddyRateReducer,
    getUserHistoryList: getUserHistoryListReducer,
    admin: adminReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
