import moment from 'moment-timezone';

const shortDateFormat = 'YYYY:DD:MM h:mm A';
const dateFormat = 'MMMM Do YYYY';
const dateFormatWithTime = 'MMMM Do YYYY, h:mm:ss a';
const standardDateFormat = 'DD/MM/YYYY';
const standardDateFormat2 = 'DD/MM/YYYY';
const dateAndMonth = 'YYYY MMMM';
const yearOnly = 'YYYY';

//date object format to standard way
export const dateFormatWithDate = (date: any) => {
  return moment(date).format(dateFormat);
};

export const dateFormatWithTimeChange = (date: any) => {
  return moment(date).format(dateFormatWithTime);
};

export const shortDateFormatWithDate = (date: any) => {
  console.log(date);
  return moment(date).format(shortDateFormat);
};

export const standardDateFormatter = (date: any) => {
  return moment(date).format(standardDateFormat);
};

export const yearAndMonthFormatter = (date: any) => {
  return moment(date).format(dateAndMonth);
};

export const yearFormatter = (date: any) => {
  return moment(date).format(yearOnly);
};

export const formattedDate = (date: any) => {
  return moment(date, standardDateFormat2).toDate();
};
