import React, { useEffect, useState } from 'react';
import { DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Card, Col, Layout, Menu, Row, theme } from 'antd';
// import { Line, Bar } from '@ant-design/charts';
// import { Column } from '@ant-design/plots';

const Dashboard: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    fetch('https://gw.alipayobjects.com/os/antfincdn/iPY8JFnxdb/dodge-padding.json')
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  };

  const config: any = {
    data,
    isGroup: true,
    xField: '月份',
    yField: '月均降雨量',
    seriesField: 'name',
    dodgePadding: 2,
    label: {
      position: 'middle',
      // 'top', 'middle', 'bottom'
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  };

  const dataBar: any = [
    {
      type: 'IT',
      sales: 38,
    },
    {
      type: 'RIC',
      sales: 52,
    },
    {
      type: 'BC',
      sales: 61,
    },
    {
      type: 'BGSU',
      sales: 145,
    },
    {
      type: 'AFA',
      sales: 48,
    },
    {
      type: 'ASU',
      sales: 38,
    },
    {
      type: 'SAB',
      sales: 38,
    },
    {
      type: 'UJP',
      sales: 38,
    },
  ];

  const configBar: any = {
    data: dataBar,
    xField: 'sales',
    yField: 'type',
    legend: {
      position: 'top-left',
    },
    barBackground: {
      style: {
        fill: 'rgba(0,0,0,0.1)',
      },
    },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
  };

  const progressConfig = {
    height: 50,
    width: 300,
    autoFit: false,
    percent: 0.536,
    barWidthRatio: 0.3,
    color: ['#F4664A', '#E8EDF3'],
  };

  return (
    // <div style={{ maxHeight: '100vh' }}>
    //   <Row style={{ margin: '5px' }} gutter={16}>
    //     <Col span={17}>
    //       <Card>
    //         Number of Ambassadors
    //         <>
    //           <Column {...config} />
    //         </>
    //       </Card>
    //     </Col>
    //     <Col span={7}>
    //       <Card>
    //         Response Rate
    //         <>
    //           <Column {...config} />
    //         </>
    //       </Card>
    //     </Col>
    //   </Row>
    //   <Row style={{ margin: '5px' }} gutter={16}>
    //     <Col span={16}>
    //       <Card>
    //         Response Time
    //         <>
    //           <Bar {...configBar} />
    //         </>
    //       </Card>
    //     </Col>
    //     <Col span={8}>
    //       <Card>
    //         Avg Ambassador Rating
    //         <div style={{ borderRadius: '5px', background: '#D3D3D3', padding: '10px', width: '100%' }}>
    //           <div style={{ display: 'flex', fontWeight: 'bold', marginBottom: '10px' }}>Ambassador 1</div>
    //           <div
    //             style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}
    //           >
    //             <div style={{ fontSize: '24px', fontWeight: 'bold' }}>56</div>
    //             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    //               <div style={{ fontSize: '18px' }}>upArrow</div>
    //               <div style={{ fontSize: '18px' }}>36</div>
    //             </div>
    //           </div>
    //           {/* Progress bar component */}
    //           <div style={{ display: 'flex' }}>{/* <Progress {...progressConfig} /> */}</div>
    //         </div>
    //       </Card>
    //     </Col>
    //   </Row>
    // </div>
    <></>
  );
};

export default Dashboard;
