import React, { useState } from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import {
  BankOutlined,
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  StarOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import styles from './SideNav.module.css';
import { RiDashboardLine } from 'react-icons/ri';
import { AiOutlineUser } from 'react-icons/ai';

const { Header, Content, Footer, Sider } = Layout;

export const SideNav = ({ style }: any) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Sider style={style} width={300} collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      <div
        style={{
          height: 32,
          margin: 16,
          background: 'rgba(255, 255, 255, 0.2)',
        }}
      />
      <Menu style={{ width: 300 }}>
        <Menu.Item key="/dashboard" className={styles.menuItem}>
          <div>
            <span style={{ marginRight: '8px' }}>
              <RiDashboardLine />
            </span>
            <Link to="/dashboard">Dashboard</Link>
          </div>
        </Menu.Item>
        <Menu.Item key="university-list" className={styles.menuItem}>
          <div>
            <span style={{ marginRight: '8px' }}>
              <BankOutlined />
            </span>
            <Link to="/university-list">University Management</Link>
          </div>
        </Menu.Item>
        {/* <Menu.Item key="university-detail" className={styles.menuItem}>
          <Link to="/university-detail">University Detail</Link>
        </Menu.Item>
        <Menu.Item key="university-create" className={styles.menuItem}>
          <Link to="/university-create">University Create</Link>
        </Menu.Item> */}
        <Menu.Item key="user-history" className={styles.menuItem}>
          <div>
            <span style={{ marginRight: '8px' }}>
              <AiOutlineUser />
            </span>
            <Link to="/user-history">Login History</Link>
          </div>
        </Menu.Item>
        <Menu.Item key="buddy-rating" className={styles.menuItem}>
          <div>
            <span style={{ marginRight: '8px' }}>
              <StarOutlined />
            </span>
            <Link to="/buddy-rating">Ratings</Link>
          </div>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
