import styles from './MainTitle.module.css'

interface MainTitleProps {
    title: string;
  }

const MainTitle: React.FC<MainTitleProps> = ({ title }) => {
    return (
        <div className={styles.mainTitle}>
            {title}
        </div>
    )
  };

export default MainTitle;