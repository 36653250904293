import React, { useEffect } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Input } from 'antd';
import './login.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks/hooks';
import { UserReqProps } from '../../Models/User';
import { clearLoginResponse, loginRequest, loginResponseSelector } from './loginSlice';
import { AuthPropType, useAuth } from '../../providers/authProvider/AuthProvider';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let { loginResponse, loginStatus, loginError } = useAppSelector(loginResponseSelector);
  let { login, user }: AuthPropType = useAuth();

  const [form] = Form.useForm();

  useEffect(() => {
    if (user) navigate('/university-list');
  }, [user, navigate]);

  useEffect(() => {
    if (loginStatus === 'SUCCESS' && login) {
      login(loginResponse);
      dispatch(clearLoginResponse());
    } else if (loginStatus === 'FAILED') {
      form.setFields([
        {
          name: 'username',
          errors: ['Invalid username or password'],
        },
        {
          name: 'password',
          errors: ['Invalid username or password'],
        },
      ]);
    }
  }, [loginStatus, loginResponse, loginError, login, dispatch, form]);

  const onFinish = async (values: UserReqProps) => {
    try {
      await dispatch(
        loginRequest({
          username: values.username,
          password: values.password,
          client_id: 'support-admin',
          grant_type: 'password',
        })
      ).unwrap();
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <div className="login-outer-div">
      <div className="form-outer-div">
        <div className="form-left-div">
          <div className="title align-center">Support Module Login</div>
          <div className="sub-title">Welcome back</div>
          <div className="divider">
            <Divider />
          </div>
          <div className="inputs-padding">
            <Form
              form={form}
              name="login_form"
              onFinish={onFinish}
              initialValues={{ remember: true }}
              layout="vertical"
            >
              <div className="input-wrapper">
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Input prefix={<UserOutlined />} size="large" className="input" />
                </Form.Item>
              </div>
              <div className="input-wrapper">
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input.Password prefix={<LockOutlined />} size="large" className="input" />
                </Form.Item>
              </div>
              <div className="remember-me-wrapper">
                <div className="font-16">
                  <Checkbox>Remember me</Checkbox>
                </div>
                <div className="font-16">Forgot Password ?</div>
              </div>
              <div className="input-wrapper">
                <Button type="primary" size="large" className="login-button" htmlType="submit">
                  Log in
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="right-column"></div>
      </div>
    </div>
  );
};

export default Login;
