import React, { useEffect } from "react";
import { Route, useNavigate, Navigate, Outlet } from "react-router-dom";
import { AuthPropType, useAuth } from "../authProvider/AuthProvider";
import MainLayout from "../../layouts/MainLayout";
import "antd/dist/reset.css";
import { Breadcrumb, Layout, Menu, Space, theme } from "antd";
import { HeaderNav } from "../../features/headerNav/HeaderNav";
import { SideNav } from "../../features/sideNav/SideNav";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { Header, Content, Footer, Sider } = Layout;

const PrivateRoutes = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    color: "black",
    height: 64,
    paddingInline: 50,
    lineHeight: "64px",
    backgroundColor: "#ffffff",
    borderBottomColor: "gray",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    zIndex: 1,
    // position: "fixed",
    width: "100%",
  };

  const contentStyle: React.CSSProperties = {
    textAlign: "center",
    minHeight: "100%",
    // lineHeight: "120px",
    color: "black",
    backgroundColor: "#ffffff",
    padding: "50px !important",
  };

  const siderStyle: React.CSSProperties = {
    // textAlign: "center",
    lineHeight: "120px",
    color: "black",
    backgroundColor: "#ffffff",
    minWidth: "300px !important",
    width: "300px !important",
    maxWidth: "300 !important",
    // background: "blue",
  };

  const footerStyle: React.CSSProperties = {
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 50px",
    // position: "fixed",
    left: "0",
    bottom: "0",
    textAlign: "center",
    color: "black",
    backgroundColor: "#ECECEC",
  };

  let { user, logout }: AuthPropType = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);

  return user ? (
    <Layout style={{ minHeight: "100vh" }}>
      <HeaderNav style={headerStyle} user={user} logout={logout} />
      <Layout hasSider>
        <SideNav style={siderStyle} />
        <Content style={contentStyle}>
          <div
            style={{
              padding: 24,
              // minHeight: 360,
              background: colorBgContainer,
            }}
          >
            <MainLayout>
              <Outlet />
              <ToastContainer limit={2} />
            </MainLayout>
          </div>
        </Content>
      </Layout>
      <Footer style={footerStyle}>
        <span style={{ fontSize: "14px", lineHeight: "normal", fontWeight: "400" }}>Powered by</span>
        <img
          style={{
            height: "18px",
            margin: "0 0 0 10px",
          }}
          src="/images/logo.png"
          alt="Univiser Logo"
        />
      </Footer>
    </Layout>
  ) : (
    <Navigate to="login" />
  );
};
export default PrivateRoutes;
