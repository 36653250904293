import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  getBuddyRatingListAPIRequest,
  updateRateStatusAPIRequest,
} from "./BuddyRatingAPI";

export interface CounterState {
  isInformationFetching: boolean;
  isUpdateFetching: boolean;
  updateStatus: "IDLE" | "PENDING" | "SUCCESS" | "FAILED";
  informationFetchingStatus: "IDLE" | "PENDING" | "SUCCESS" | "FAILED";
  response: [];
  updateResponse: {};
}

export interface UpdateStatusProps {
  status?: any;
  id?: number;
}

const initialState: CounterState = {
  isInformationFetching: false,
  informationFetchingStatus: "IDLE",
  response: [],
  isUpdateFetching: false,
  updateStatus: "IDLE",
  updateResponse: {},
};

export const getBuddyRatingListRequest = createAsyncThunk(
  "buddy/getBuddyRate",
  async () => {
    return await getBuddyRatingListAPIRequest();
  }
);

export const updateRateStatusRequest = createAsyncThunk(
  "buddy/updateBuddyRate",
  async (data: UpdateStatusProps) => {
    return await updateRateStatusAPIRequest(data);
  }
);

const getBuddyRateSlice = createSlice({
  name: "getBuddyRate",
  initialState: initialState,
  reducers: {
    clearUniversity: (state) => {
      state.isInformationFetching = false;
      state.informationFetchingStatus = "IDLE";
      state.response = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBuddyRatingListRequest.pending, (state) => {
        state.informationFetchingStatus = "PENDING";
        state.isInformationFetching = true;
      })
      .addCase(getBuddyRatingListRequest.fulfilled, (state, action: any) => {
        // Add user to the state array
        state.informationFetchingStatus = "SUCCESS";
        state.isInformationFetching = false;
        state.response = action.payload;
      })
      .addCase(getBuddyRatingListRequest.rejected, (state) => {
        state.informationFetchingStatus = "FAILED";
        state.isInformationFetching = false;
      })
      .addCase(updateRateStatusRequest.pending, (state) => {
        state.updateStatus = "PENDING";
        state.isUpdateFetching = true;
      })
      .addCase(updateRateStatusRequest.fulfilled, (state, action: any) => {
        // Add user to the state array
        state.updateStatus = "SUCCESS";
        state.isUpdateFetching = false;
        state.updateResponse = action.payload;
      })
      .addCase(updateRateStatusRequest.rejected, (state) => {
        state.updateStatus = "FAILED";
        state.isUpdateFetching = false;
      });
  },
});

export const getBuddyRatingSelector = (state: RootState) => state.getBuddyRate;

export const { clearUniversity } = getBuddyRateSlice.actions;

export default getBuddyRateSlice.reducer;
