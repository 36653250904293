import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import 'antd/dist/reset.css';
import Dashboard from './layouts/dashboard/Dashboard';
import Login from './layouts/login/login';
import PrivateRoutes from './providers/routeProvider/PrivateRoutes';
import BuddyRating from './layouts/rating/BuddyRating';
import UserLoginHistoryList from './layouts/user-history/UserLoginHistoryList';

const UniversityManagement = React.lazy(() => import('./layouts/management/UniversityManagement'));
const UniversityDetail = React.lazy(() => import('./layouts/management/UniversityDetail'));
const UniversityCreate = React.lazy(() => import('./layouts/management/UniversityCreate'));

function App() {
  //add routing here
  return (
    <Routes>
      {/*public routes*/}
      <Route path="/" element={<Login />} />
      {/*private Routes*/}
      <Route element={<PrivateRoutes />}>
        <Route path="/university-list" element={<UniversityManagement />} />
        <Route path="/university-detail" element={<UniversityDetail />} />
        {/* <Route path="/faqDescription/:id" element={<FAQDescription/>}/> */}
        <Route path="/university-create" element={<UniversityCreate />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/user-history" element={<UserLoginHistoryList />} />
        <Route path="*" element={<Login />} />
        <Route path="/buddy-rating" element={<BuddyRating />} />
      </Route>
    </Routes>
  );
}

export default App;
