import { Button, Space, Table, Tag, Modal, Divider, Rate } from "antd";
import type { ColumnsType } from "antd/es/table";
import styles from "./ListSection.module.css";
import { useNavigate } from "react-router-dom";
import { ratingList } from "../../../app/constant";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import {
  getBuddyRatingListRequest,
  getBuddyRatingSelector,
  updateRateStatusRequest,
} from "./BuddyRatingSlice";
import moment from "moment";

interface DataType {
  id?: number;
  prospectName?: string;
  ratings?: number;
  comment?: string;
  createdDate?: string | null; // LocalDateTime will be serialized as null if not set
  status?: null | "ACCEPTED" | "REJECTED" | "PENDING"; // Enum will be serialized as null if not set
  buddyName?: string;
  uniName?: string;
}

const ListSection: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    isInformationFetching,
    informationFetchingStatus,
    response,
    updateStatus,
  } = useAppSelector(getBuddyRatingSelector);
  const [dataList, setDataList] = useState<any>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [buddyId, setBuddyId] = useState<any>(null);
  const [buddyRateData, setBuddyRateData] = useState<any>({});

  useEffect(() => {
    dispatch(getBuddyRatingListRequest());
  }, []);

  useEffect(() => {
    if (informationFetchingStatus === "SUCCESS") {
      setDataList(response);
    }
  }, [informationFetchingStatus]);

  useEffect(() => {
    if (updateStatus === "SUCCESS") {
      dispatch(getBuddyRatingListRequest());
    }
  }, [updateStatus]);

  const renderStatus = (status: string) => {
    let color =
      status === "ACCEPTED" ? "green" : status === "PENDING" ? "yellow" : "red";
    return (
      <Space size="middle">
        <Tag color={color}>{status}</Tag>
      </Space>
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "University",
      dataIndex: "uniName",
      key: "uniName",
    },
    {
      title: "Buddy Name",
      dataIndex: "buddyName",
      key: "buddyName",
    },
    {
      title: "Prospect",
      dataIndex: "prospectName",
      key: "prospectName",
    },
    {
      title: "Created on",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (_, record) => (
        <>{moment(record?.createdDate).format("DD/MM/YYYY")}</>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return (
          <>{record?.status == null ? "- " : renderStatus(record?.status)}</>
        );
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => visibleModal(record)}>
            <>{record?.status !== "PENDING" ? "Change" : "View"}</>
          </Button>
        </Space>
      ),
    },
  ];

  const data: DataType[] = dataList;

  const visibleModal = (data: any) => {
    setVisible(true);
    // setBuddyId(id);
    setBuddyRateData(data);
  };

  const handleReject = () => {
    dispatch(
      updateRateStatusRequest({ id: buddyRateData.id, status: "REJECTED" })
    );
    setVisible(false);
  };

  const handleAccept = () => {
    dispatch(
      updateRateStatusRequest({ id: buddyRateData.id, status: "ACCEPTED" })
    );
    setVisible(false);
  };

  const renderButtons = (record: any) => {
    return (
      <div>
        {record?.status === "PENDING" || record?.status === "ACCEPTED" ? (
          <Button onClick={handleReject} className={styles.rejectButton}>
            Reject
          </Button>
        ) : null}
        {record?.status === "PENDING" || record?.status === "REJECTED" ? (
          <Button
            key="submit"
            loading={loading}
            onClick={handleAccept}
            type="primary"
            className={styles.acceptButton}
          >
            Accept
          </Button>
        ) : null}
      </div>
    );
  };

  return (
    <div className={styles.listSectionOuter}>
      <Table columns={columns} dataSource={data} />
      <Modal
        open={visible}
        title={
          <>
            <div className={styles.modalTitle}>Buddy Rating</div>
            <div className={styles.modalSubTitle}>
              Check new buddy rating requests
            </div>
            <Divider />
          </>
        }
        onOk={handleAccept}
        onCancel={() => setVisible(false)}
        footer={renderButtons(buddyRateData)}
      >
        <div className={styles.detailDiv}>
          <div className={styles.subDetailDiv}>
            <div className={styles.titleLabel}>University</div>
            <div className={styles.valueLabel}>{buddyRateData?.uniName}</div>
          </div>
          <div className={styles.subDetailDiv}>
            <div className={styles.titleLabel}>Buddy Name</div>
            <div className={styles.valueLabel}>{buddyRateData?.buddyName}</div>
          </div>
          <div className={styles.subDetailDiv}>
            <div className={styles.titleLabel}>Prospect</div>
            <div className={styles.valueLabel}>
              {buddyRateData?.prospectName}
            </div>
          </div>
          <div className={styles.subDetailDiv}>
            <div className={styles.titleLabel}>Rate</div>
            <div className={styles.valueLabel}>
              {buddyRateData?.ratings && (
                <Rate disabled defaultValue={buddyRateData?.ratings} />
              )}
            </div>
          </div>
          <div className={styles.subDetailDiv}>
            <div className={styles.titleLabel}>Feedback</div>
            <div className={styles.commentBox}>
              <div className={styles.valueLabel}>{buddyRateData?.comment}</div>
            </div>
          </div>
          <Divider />
        </div>
      </Modal>
    </div>
  );
};

export default ListSection;
