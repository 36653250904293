import React, { useState } from 'react';
import { Breadcrumb, Layout, Menu, theme, Col, Row, Badge, Popover, Divider } from 'antd';
import {
  BellOutlined,
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
// import 'ant-design-pro/dist/ant-design-pro.css';
import styles from './HeaderNav.module.css';

const { Header, Content, Footer, Sider } = Layout;

export const HeaderNav = ({ style, user, logout }: any) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleLogout = () => {};

  const text = (
    <>
      <div className={styles.popOverTitle}>Admin</div>
      <Divider />
    </>
  );
  const content = (
    <div className={styles.popOverContent}>
      <div onClick={() => logout()}>
        <span className={styles.userIcon}>
          <UserOutlined />
        </span>
        Logout
      </div>
    </div>
  );

  return (
    <Header style={style}>
      <Row>
        <Col span={2}>
          <img src="/images/Support-module-logo-2.png" alt="logo" />
        </Col>
        <Col span={2} offset={20}>
          <Popover placement="bottom" trigger="click" title={text} content={content}>
            <div className={styles.userOuterDiv}>
              <Badge count={5}>
                <BellOutlined style={{ fontSize: '24px' }} />
              </Badge>
              <div className={styles.userDiv}>
                <div className={styles.roundedDiv}>A</div>
                <div>Admin</div>
              </div>
            </div>
          </Popover>
        </Col>
      </Row>
    </Header>
  );
};
