import React, { useEffect, useState } from 'react';

import { Avatar, Col, Table, Typography, Tooltip, Row, Card, Button, Modal, Tabs } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { useAppDispatch, useAppSelector } from '../../app/hooks/hooks';
import { getAllUserHistoryList, getUserHistoryListSelector } from './UserHistoryListSlice';
import { shortDateFormatWithDate } from '../../utils/helpers/fomatter';

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE_LIST = [10, 20];

function UserLoginHistoryList({ universityId }: any) {
  console.log('UserLoginHistoryList university ID:', universityId);
  const dispatch = useAppDispatch();
  const userHistoryList: any = useAppSelector(getUserHistoryListSelector);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const [showModel, setShowModel] = useState(false);
  const [viewedUser, setViewedUser] = useState<any>({});

  useEffect(() => {
    if (universityId) {
      dispatch(
        getAllUserHistoryList({
          universityId,
          page: page - 1,
          size: pageSize,
        })
      );
    }
  }, [universityId, page, pageSize, dispatch]);

  const columns = [
    {
      dataIndex: ['name'],
      key: 'name',
      title: 'Name',
      render: (value: any, record: any) => <>{record?.user?.name}</>,
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: any, record: any) => <>{shortDateFormatWithDate(record?.createdAt)}</>,
    },
  ];

  return (
    <>
      <Col className="left_col" style={{ marginTop: '15px' }}>
        <div className="col_wrapper">
          <Table
            columns={columns}
            dataSource={userHistoryList?.userHistoryList}
            sticky
            pagination={{
              onShowSizeChange: (current, size) => {
                setPage(0);
                setPageSize(size);
              },
              onChange: (current, size) => {
                setPage(current);
                setPageSize(size);
              },
              defaultCurrent: page,
              total: userHistoryList?.userHistoryList?.totalItems,
              pageSize: pageSize,
              pageSizeOptions: DEFAULT_PAGE_SIZE_LIST,
            }}
          />
        </div>
      </Col>

      {viewedUser && (
        <Modal
          title={`${viewedUser?.name} information`}
          open={showModel}
          onCancel={() => setShowModel(!showModel)}
          footer={null}
        >
          <Row>
            <Col span={24}>
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    key: '1',
                    label: 'Personal details',
                    children: (
                      <>
                        <Row>
                          <Col span={12}>
                            <p className="main-text-p">First Name</p>
                            <p className="sub-text-p">{viewedUser?.name}</p>
                          </Col>
                          <Col span={12}>
                            <p className="main-text-p">Email</p>
                            <p className="sub-text-p">{viewedUser?.email}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p className="main-text-p">Mobile number</p>
                            <p className="sub-text-p">{viewedUser?.phone}</p>
                          </Col>
                        </Row>
                      </>
                    ),
                  },
                  {
                    key: '2',
                    label: 'Course details',
                    children: '-',
                  },
                ]}
              />
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
}

export default UserLoginHistoryList;
