import { Col, Row } from "antd";
import MainTitle from "../../common/mainTitle/MainTitle";
import styles from './BuddyRating.module.css';
import ListSection from "./ListSection";

const BuddyRatingView: React.FC = () => {
  return (
    <>
      <Row className={styles.outerRow}>
        <Col span={8}>
          <MainTitle title="Buddy Rating" />
          <div className={styles.subTitle}>Check new buddy rating reqests and rating history</div>
        </Col>
      </Row>
      <ListSection />
    </>
  );
};

export default BuddyRatingView;
