import { authenticatedRequest } from '../../../utils/axios/commonAxios';
import {
  UniversityBrandingProps,
  UniversityConfigurationProps,
  UniversityIntegrationProps,
  UniversityNameProps,
  UniversityProgramsProps,
  UniversityStatusProps,
  UniversityRegionalProps,
} from './detailInterfaces';

export const getUniversityAPIRequest = async (id: number) => {
  return await authenticatedRequest(`${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${id}`, 'get', {});
};

export const deleteUniversityAPIRequest = async (id: number) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${id}`,
    'delete',
    {}
  );
};

export const updateUniversityNameAPIRequest = async (data: UniversityNameProps) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${data?.id}/university-name`,
    'put',
    {
      data: {
        name: data?.name,
        shortName: data?.shortName,
        domain: data?.domain,
      },
    }
  );
};

export const updateUniversityBrandingAPIRequest = async (data: UniversityBrandingProps) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${data?.id}/university-branding`,
    'put',
    {
      data: {
        logoUrl: data?.logoUrl,
        favIconUrl: data?.favIconUrl,
        primaryColor: data?.primaryColor,
        secondaryColor: data?.secondaryColor,
        tagLine: data?.tagLine,
        defaultCoverPhotoUrl: data?.defaultCoverPhotoUrl,
        defaultProfilePhotoUrl: data?.defaultProfilePhotoUrl,
      },
    }
  );
};

export const updateUniversityProgramAPIRequest = async (data: UniversityProgramsProps) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${data?.id}/university-programmes`,
    'put',
    {
      data: {
        programmes: data?.programs,
      },
    }
  );
};

export const updateUniversityConfigurationAPIRequest = async (data: UniversityConfigurationProps) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${data?.id}/university-configurations`,
    'put',
    {
      data: data,
    }
  );
};

export const updateUniversityIntegrationAPIRequest = async (data: UniversityIntegrationProps) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${data?.id}/integration`,
    'put',
    {
      data: data,
    }
  );
};

export const updateUniversityStatusAPIRequest = async (data: UniversityStatusProps) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${data?.id}/change-status`,
    'put',
    {
      data: data,
    }
  );
};

export const getUniversityRegionalAPIRequest = async (id: number) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${id}/university-configurations`,
    'get'
  );
};

export const updateUniversityRegionalAPIRequest = async (data: UniversityRegionalProps) => {
  console.log('Sending API request with data:', data);
  const response = await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${data?.id}/system`,
    'put',
    {
      data: {
        countryList: data?.countryList,
        interestList: data?.interestList,
        clientLanguageList: data?.clientLanguageList,
      },
    }
  );
  console.log('API Response:', response);
  return response;
};

export const updateUniversityStateAPIRequest = async (data: UniversityConfigurationProps) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/${data.id}/state`,
    'post',
    {
      data: {
        stateList: data.stateList,
      },
    }
  );
};

export const deleteStateAPIRequest = async (id: number) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_BACKEND_HOST}/support/api/university/v2/state/${id}`,
    'delete',
    {}
  );
};
