import BuddyRatingView from "../../features/rating/buddyRating/BuddyRating"

const BuddyRating: React.FC = () => {
    return (
        <>
            <BuddyRatingView />
        </>
    )
}

export default BuddyRating;